// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

@import "primeflex/primeflex.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $moj-front-end-primary: mat.define-palette(mat.$indigo-palette);
// $moj-front-end-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $moj-front-end-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $moj-front-end-theme: mat.define-light-theme((
//   color: (
//     primary: $moj-front-end-primary,
//     accent: $moj-front-end-accent,
//     warn: $moj-front-end-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($moj-front-end-theme);

/* You can add global styles to this file, and also import other style files */
html {
  // used to scale the entire application
  font-size: 15px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main-card {
  padding: 10px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nocredit {
  background-color: #F7CFD3;
  color: #A82E25;
}
